import { Icon, type IconName } from '../ui/Icon'

type InfoBlockProps = {
  iconName?: IconName;
  title?: string;
  content?: string;
}

export const InfoBlock = ( { iconName, title, content }: InfoBlockProps ) => {
  return (
    <div className={ 'flex flex-col items-center gap-2' }>
      { iconName &&
        <Icon
          name={ iconName }
          className={ 'icon-size-6' } />
      }
      { title &&
        <p className={ 'text-center text-2xl font-semibold font-onest' }>{ title }</p>
      }
      { content &&
        <p className={ 'text-center font-light' }>{ content }</p>
      }
    </div>
  )
}